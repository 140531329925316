/**
 *  Internet Explorer does not provide the leading slash character in the pathname
 * (docs/Web/API/Location instead of /docs/Web/API/Location).
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Location/pathname
 */

/**
 * @returns {string}
 */
function path () {
  const pathname = window.location.pathname
  return pathname[0] === '/'
    ? pathname
    : `/${pathname}`
}

function referrerPath () {
  return document.referrer.split(window.location.host)[1]
}

export default {
  path,
  referrerPath
}
