export default function trackBubbleSelect (element, index) {
  const ga = {
    eventCategory: 'bubble',
    eventAction: 'bubble-select',
    eventLabel: index + 1,
    dimension1: element.getAttribute('href'),
    dimension2: element.getAttribute('alt') || 'not set'
  }

  element.addEventListener('click', () => {
    if (window.coreAnalytics) {
      window.coreAnalytics('event', { ga })
    }
  })
}
