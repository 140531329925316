import idHelper from './id-helper'

function prefixProgramId (programId) {
  if (idHelper.isProgramId(programId)) {
    return `prf:${programId}`
  }

  if (idHelper.isPotionId(programId)) {
    return `potion:${programId}`
  }
}

export default {
  prefixProgramId
}
