import analyticsHelper from '../../utils/analytics-helper'

export default function trackTileSelect (element) {
  const targetType = element.getAttribute('data-target-type')
  const ga = {
    eventCategory: 'ocean',
    eventAction: 'tile-select',
    eventLabel: element.getAttribute('data-index'),
    dimension2: element.getAttribute('data-title'),
    dimension23: 'pages'
  }

  if (targetType === 'channel') {
    ga.dimension1 = `live:${element.getAttribute('data-channel-id')}`
  }

  if (targetType === 'series') {
    ga.dimension1 = `tvseries:${element.getAttribute('data-series-id')}`
  }

  if (targetType === 'episode') {
    ga.dimension1 = analyticsHelper.prefixProgramId(element.getAttribute('data-program-id'))
    ga.dimension21 = element.getAttribute('data-series-id')
  }

  if (targetType === 'standaloneProgram') {
    ga.dimension1 = analyticsHelper.prefixProgramId(element.getAttribute('data-program-id'))
  }

  element.addEventListener('click', () => {
    if (window.coreAnalytics) {
      window.coreAnalytics('event', { ga })
    }
  })
}
