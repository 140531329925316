/* @see https://github.com/nrkno/PS.Frontend/blob/master/Nrk.PsApi/Nrk.PsApi.Playback/Mappers/IdHelper.cs */
const idPatterns = {
  /** @type {RegExp} */
  programId: /^[a-zA-ZøæåØÆÅ]{4}[0-9]{8}(.*)?/,

  /** @type {RegExp} */
  potionId: /^(\{{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}\}{0,1})$/
}

function isProgramId (value) {
  return idPatterns.programId.test(value)
}

function isPotionId (value) {
  return idPatterns.potionId.test(value)
}

export default {
  isProgramId,
  isPotionId
}
