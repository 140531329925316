function get (key) {
  try {
    return JSON.parse(window.localStorage.getItem(key))
  } catch (e) {
    // it's ok
  }
}

function set (key, value) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value))
  } catch (e) {
    // it's ok
  }
}

function remove (key) {
  try {
    window.localStorage.removeItem(key)
  } catch (e) {
    // it's ok
  }
}

export default {
  get,
  set,
  remove
}
