import localStorageAdapter from '../../utils/local-storage-adapter'

const maxItems = 3
const localStorageKey = 'lastSeenItems'

// Update version if there any changes to the item format
const version = '1.0.0'

function getAll () {
  return localStorageAdapter.get(localStorageKey) || []
}

function set (content) {
  localStorageAdapter.set(localStorageKey, content)
}

function clear () {
  localStorageAdapter.remove(localStorageKey)
}

function update (item) {
  const content = getAll()
  const storedEntry = content.filter(elem => elem.item.id === item.id)[0]
  if (storedEntry) {
    const index = content.indexOf(storedEntry)
    content[index] = {
      updated: Date.now(),
      item: storedEntry.item,
      version
    }
  } else {
    if (content.length >= maxItems) {
      content.pop()
    }
    content.unshift({
      updated: Date.now(),
      item,
      version
    })
  }

  content.sort((a, b) => b.updated > a.updated)
  set(content)
}

// ensure correct version is set for every item
const items = getAll()
const itemsFiltered = items.filter(item => item.version && item.version === version)
if (itemsFiltered.length !== items.length) {
  set(itemsFiltered)
}

export default {
  clear,
  update,
  getAll () {
    return getAll().map(elem => elem.item)
  }
}
