function trackPromoSelect () {
  // no promo element to track
  const element = document.querySelector('.ocean__promo a')
  if (!element) {
    throw new Error('Promo element is missing')
  }

  const href = element.getAttribute('href')

  const titleElement = element.querySelector('h2')
  const title = titleElement
    ? titleElement.innerHTML
    : 'not set'

  const ga = {
    eventCategory: 'ocean',
    eventAction: 'promo-select',
    dimension1: href,
    dimension2: title
  }

  element.addEventListener('click', () => {
    if (window.coreAnalytics) {
      window.coreAnalytics('event', { ga })
    }
  })
}

function load () {
  trackPromoSelect()
}

export default {
  load
}
