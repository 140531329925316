/* global WebKitCSSMatrix, MSCSSMatrix */
import nodelistToArray from '../../utils/nodelist-to-array'
import trackBubbleSelect from './track-bubble-select'

let translateBlock
let bubblesContainer
let backgroundBubbles
let foregroundBubbles
let singleBubbles
let rectangle
let scrollableToleft
let scrollableToRight
let scrollableBothWays
let leftArrow
let rightArrow

const touchscreen = window.matchMedia('(any-pointer: coarse)').matches
const context = {
  scroll: false,
  bgType: ''
}

function getTranslateMatrix () {
  const style = window.getComputedStyle(backgroundBubbles)

  // WebKitCSSMatrix works on most major browsers
  if (window.WebKitCSSMatrix) {
    return new WebKitCSSMatrix(style.webkitTransform)
  }

  // MSCSSMatrix for IE11
  if (window.MSCSSMatrix) {
    return new MSCSSMatrix(style.transform)
  }
}

function swipeBubbles (e, startX) {
  const touchobj = e.changedTouches[0]
  const distX = touchobj.pageX - startX // get horizontal dist traveled by finger while in contact with surface

  // Swipe to left
  if (translateBlock.scrollWidth !== translateBlock.clientWidth) {
    backgroundBubbles.classList.remove('background-bubbles-margin-left')
  }

  // Swipe Right
  if (distX > 0 && translateBlock.scrollLeft === 0) {
    backgroundBubbles.classList.add('background-bubbles-margin-left')
  }
}

function scrollBubbles (right) {
  let scrollLen = 0
  if (right) {
    const rightValue = parseInt(rectangle.right) - translateBlock.clientWidth >= 0 ? parseInt(rectangle.right) - translateBlock.clientWidth : 0
    scrollLen = Math.min(translateBlock.scrollWidth - translateBlock.clientWidth, rightValue)
  }

  const matrix = getTranslateMatrix()
  const duration = right === true ? Math.round(scrollLen / 570) : Math.round(0 - (matrix.m41) / 570)
  backgroundBubbles.style.transitionDuration = duration === 0 ? '0.5s' : `${duration}s`
  if (right === true) {
    backgroundBubbles.style.transform = `translate(${matrix.m41 - scrollLen}px)`
  }
}

function scrollRight () {
  const rightValue = parseInt(rectangle.right) - translateBlock.clientWidth >= 0 ? parseInt(rectangle.right) - translateBlock.clientWidth : 0
  if (translateBlock.scrollWidth - translateBlock.clientWidth === 0 || rightValue === 0) {
    rightArrow.classList.add('scroll-hide')
  }
}

function scrollOnClick (scrollLen, direction) {
  const scrollIterate = (scrollLen < 200 ? scrollLen : 200)
  const matrix = getTranslateMatrix().m41
  let value = matrix - scrollIterate
  if (direction === 'left') {
    value = matrix + scrollIterate
  }

  backgroundBubbles.style.transitionDuration = '0.1s'
  backgroundBubbles.style.transform = `translate(${value}px)`
}

function isScrollable () {
  rectangle = backgroundBubbles.getBoundingClientRect()
  scrollableBothWays = translateBlock.clientWidth < foregroundBubbles.scrollWidth || translateBlock.clientWidth < foregroundBubbles.clientWidth
  scrollableToleft = translateBlock.scrollWidth !== foregroundBubbles.scrollWidth || parseInt(rectangle.left) < 0
  scrollableToRight = translateBlock.scrollWidth !== translateBlock.clientWidth || translateBlock.clientWidth < parseInt(rectangle.right) // For firefox
  if (scrollableBothWays) {
    singleBubbles[0].querySelector('.bubble-title').classList.add('bubble-title-left')
    return true
  }
}

function hideScroll () {
  leftArrow.classList.add('scroll-hide')
  rightArrow.classList.add('scroll-hide')
}

function setScrollDirection () {
  if (scrollableToleft) {
    leftArrow.classList.remove('scroll-hide')
  }
  if (scrollableToRight) {
    rightArrow.classList.remove('scroll-hide')
  }
}

function leftAlignBubbles () {
  backgroundBubbles.classList.add('background-bubbles-left')
  foregroundBubbles.classList.remove('align-bubbles')
  backgroundBubbles.classList.remove(`background-bubbles-middle-${context.bgType}`)
}

function centerAlignBubbles () {
  backgroundBubbles.classList.add(`background-bubbles-middle-${context.bgType}`)
  backgroundBubbles.classList.remove('background-bubbles-left')
  foregroundBubbles.classList.add('align-bubbles')
}

function updateBubblesPanelContext () {
  context.scroll = isScrollable()
  // Sets the width of background image based on number of filled bubbles
  backgroundBubbles.style.minWidth = `${foregroundBubbles.scrollWidth}px`
  if (context.scroll || window.innerWidth < 770) {
    leftAlignBubbles()
  } else {
    centerAlignBubbles()
  }
}

function setUpdateBubbleTimeout () {
  updateBubblesPanelContext()
  setTimeout(setUpdateBubbleTimeout, 1000)
}

function setupBubblesPanel () {
  bubblesContainer = document.querySelector('.bubbles-container')
  translateBlock = document.querySelector('.transition-wrapper')
  backgroundBubbles = document.querySelector('.background-bubbles')
  foregroundBubbles = document.querySelector('.foreground-bubbles')
  singleBubbles = document.querySelectorAll('.single-bubble')
  leftArrow = document.getElementById('arrow-left')
  rightArrow = document.getElementById('arrow-right')
  context.bgType = singleBubbles.length % 2 === 0 ? 'even' : 'odd' // To add different background images based on the count of filled bubbles

  if (touchscreen) {
    let startX
    backgroundBubbles.addEventListener('touchstart', (e) => {
      const touchobj = e.changedTouches[0]
      startX = touchobj.pageX
    })
    backgroundBubbles.addEventListener('touchend', (e) => {
      swipeBubbles(e, startX)
    })
  }

  backgroundBubbles.addEventListener('mouseover', (e) => {
    if (e.currentTarget === backgroundBubbles && context.scroll) {
      setScrollDirection()
    }
  })

  bubblesContainer.addEventListener('mouseleave', (e) => {
    e.stopPropagation()
    if (e.currentTarget === bubblesContainer) {
      hideScroll()
    }
  })

  rightArrow.addEventListener('mouseover', (e) => {
    e.stopPropagation()
    leftArrow.classList.add('scroll-hide')
    scrollBubbles(true)
    scrollRight()
  })

  rightArrow.addEventListener('mouseout', (e) => {
    e.stopPropagation()
    rightArrow.classList.add('scroll-hide')
    backgroundBubbles.style.transform = `translate(${getTranslateMatrix().m41}px)`
  })

  leftArrow.addEventListener('mouseover', (e) => {
    e.stopPropagation()
    rightArrow.classList.add('scroll-hide')
    scrollBubbles(false)
    backgroundBubbles.style.transform = 'translate(0px)'
  })

  leftArrow.addEventListener('mouseout', (e) => {
    e.stopPropagation()
    leftArrow.classList.add('scroll-hide')
    backgroundBubbles.style.transform = `translate(${getTranslateMatrix().m41}px)`
  })

  rightArrow.addEventListener('click', (e) => {
    const scrollLen = translateBlock.scrollWidth - translateBlock.clientWidth

    if (touchscreen && window.innerWidth > 720 && window.innerWidth < 1440 && scrollLen > 10) {
      if (scrollLen <= 200) {
        setTimeout(() => {
          rightArrow.classList.add('scroll-hide')
        }, 100)
      }
      scrollOnClick(scrollLen)
    }
  })

  leftArrow.addEventListener('click', (e) => {
    if (touchscreen && window.innerWidth > 720 && window.innerWidth < 1440) {
      const scrollLen = backgroundBubbles.scrollWidth - translateBlock.scrollWidth
      if (scrollLen <= 200) {
        setTimeout(() => {
          leftArrow.classList.add('scroll-hide')
        }, 100)
      }
      scrollOnClick(scrollLen, 'left')
    }
  })

  // Sets following contexts: odd, even, center aligned, left aligned, scrollable, not scrollable
  setUpdateBubbleTimeout()

  window.addEventListener('resize', () => {
    scrollBubbles(false)
    backgroundBubbles.style.transform = 'translate(0px)'
    // Sets context again on resize
    updateBubblesPanelContext()
  })
}

function trackBubbles () {
  nodelistToArray(
    document.querySelectorAll('.bubbles-container .single-bubble a')
  ).forEach((element, index) => trackBubbleSelect(element, index))
}

function load () {
  // no bubbles
  if (!document.querySelector('.bubbles-container')) {
    return
  }

  setupBubblesPanel()
  trackBubbles()
}

export default {
  load
}
