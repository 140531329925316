import localStorageAdapter from '../../utils/local-storage-adapter'
import location from '../../utils/location'
import nodelistToArray from '../../utils/nodelist-to-array'

const localStorageKey = 'recommendationsContent'

// Update version if there any changes to the item format
const version = '1.0.0'

// If the versions do not match delete the existing stored item
const item = localStorageAdapter.get(localStorageKey) || {}
if (item.version !== version) {
  localStorageAdapter.remove(localStorageKey)
}

function set (item) {
  const content = {
    version,
    item,
    path: location.path()
  }

  localStorageAdapter.set(localStorageKey, content)
}

/**
 * @param {string} list
 * @param {string} brand
 * @param {Element} element
 */
function trackListElement (list, brand, element) {
  const props = {
    list,
    brand,
    id: element.getAttribute('data-ec-id'),
    variant: element.getAttribute('data-ec-variant'),
    category: element.getAttribute('data-ec-category'),
    position: element.getAttribute('data-ec-position')
  }

  element.addEventListener('click', () => set(props))
}

/**
 * @param {Element} element
 * @param {string} brand
 */
function trackList (element, brand) {
  const list = element.getAttribute('data-ec-list')

  /** @type {Element[]} */
  nodelistToArray(element.querySelectorAll('[data-ec-id]'))
    .forEach(element => trackListElement(list, brand, element))
}

/**
 * Track ec lists
 */
function trackLists () {
  const brand = window.eCommerce.brand

  /** @type {Element[]} */
  nodelistToArray(document.querySelectorAll('[data-ec-list]'))
    .forEach(element => trackList(element, brand))
}

/**
 * Get recommended content
 * @returns {object}
 */
function recommendationsContent () {
  const content = localStorageAdapter.get(localStorageKey) || {}
  if (!content.item || !content.item.id) {
    return null
  }

  return content
}

function isECommerceEnabled () {
  return window.eCommerce && window.eCommerce.trackingExempt !== true
}

/**
 * Validate recommendations content for program
 * @param {object} content
 * @param {string} contentId
 * @returns {boolean}
 */
function validRecommendationsContentForProgram (content, contentId) {
  // program not the same
  if (content.item.id !== contentId) {
    return false
  }

  // not the same path from where the item was clicked
  if (location.referrerPath() !== content.path) {
    return false
  }

  return true
}

/**
 * Validate recommendations content for series
 * @param {object} content
 * @param {string} contentId
 * @param {string} contentGroupId
 * @returns {boolean}
 */
function validRecommendationsContentForSeries (content, contentId, contentGroupId) {
  const isSeries = content.item.id.indexOf('tvseries:') !== -1
  if (!isSeries) {
    return validRecommendationsContentForProgram(content, contentId)
  }

  // series is not the same
  if (content.item.id !== contentGroupId) {
    return false
  }

  return true
}

/**
 * Configures default recommendations with contentId = window.eCommerce.brand
 * (@see templates/layouts/default.hbs)
 */
function configureDefault () {
  if (!isECommerceEnabled()) {
    return
  }

  // no ec list(s) to track
  if (!document.querySelector('[data-ec-list]')) {
    return
  }

  window.coreAnalytics('recommendations', { contentId: window.eCommerce.brand })

  // track the ec lists
  trackLists()
}

/**
 * Configures player recommendation for program or series content
 *
 * @param {Player} ludoInstance
 * @param {string} contentId
 * @param {string|null} contentGroupId
 */
function configurePlayerContent (ludoInstance, contentId, contentGroupId = null) {
  if (!isECommerceEnabled()) {
    return
  }

  const content = recommendationsContent()
  if (!content) {
    configureDefault()
    return
  }

  // validate program recommendations
  if (contentId && !contentGroupId && !validRecommendationsContentForProgram(content, contentId)) {
    localStorageAdapter.remove(localStorageKey)
    configureDefault()
    return
  }

  // validate series or episode recommendations
  if (contentId && contentGroupId && !validRecommendationsContentForSeries(content, contentId, contentGroupId)) {
    localStorageAdapter.remove(localStorageKey)
    configureDefault()
    return
  }

  const props = {
    contentId,
    ludoInstance
  }

  if (contentGroupId) {
    props.contentGroupId = contentGroupId
  }

  window.coreAnalytics('recommendations', props)

  // remove the recommendationsItem if it was completed or should we always remove it
  // what if the user reloads the page?
  ludoInstance.on('ended', () => localStorageAdapter.remove(localStorageKey))

  // track the ec lists
  trackLists()
}

export default {
  configureDefault,
  configurePlayerContent
}
