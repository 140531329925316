import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

if (!window.Promise) {
  // Since promise-polyfill@8.0 its export is an ESM module, not CommonJS
  // See: https://github.com/taylorhakes/promise-polyfill/blob/master/CHANGELOG.md#800
  window.Promise = require('promise-polyfill').default
}

if (!window.requestAnimationFrame) {
  window.requestAnimationFrame = require('raf')
}
require('whatwg-fetch')
require('./custom-event')
require('svgxuse')
