import '../../polyfills'
import bubblesPanel from '../../components/bubbles/panel'
import promoPanel from '../../components/promo/panel'
import shelfsPanel from '../../components/shelfs/panel'
import recommendations from '../../components/recommendations'

document.addEventListener('DOMContentLoaded', event => {
  // load panels
  bubblesPanel.load()
  promoPanel.load()
  shelfsPanel.load()

  // configure recommendations default
  recommendations.configureDefault()
})
