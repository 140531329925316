import nodelistToArray from '../../utils/nodelist-to-array'
import convertToSrcSet from '../../utils/convert-to-src-set'
import lastSeenStore from '../last-seen-store'
import trackTileSelect from './track-tile-select'

function querySelectorAllAndRemoveClass (selector, className) {
  [...document.querySelectorAll(selector)]
    .forEach(elem => elem.classList.remove(className))
}

function showSources () {
  if (window.location.href.indexOf('withSources') !== -1) {
    querySelectorAllAndRemoveClass('[data-ocean-element-type-wrapper]', 'sup-dn')
    querySelectorAllAndRemoveClass('[data-ocean-element-slot-wrapper]', 'sup-dn')
  }
}

function setAttributeOrRemove (element, key, value) {
  if (value) {
    element.setAttribute(key, value)
    return
  }

  element.removeAttribute(key)
}

function updateLastSeenSlots () {
  const lastSeenSlots = nodelistToArray(
    document.querySelectorAll('.ocean__element--last-seen-slot')
  )

  if (lastSeenSlots.length === 0) {
    return
  }

  const items = lastSeenStore.getAll()
  for (let i = 0, l = items.length; i < l; i++) {
    const slot = lastSeenSlots[i]
    if (!slot) {
      return
    }

    const item = items[i]
    const element = slot.querySelector('a.shelf-element-link')
    slot.querySelector('.shelf-element-link-title').innerText = item.title

    element.setAttribute('href', item.url)
    element.setAttribute('data-title', item.title)

    setAttributeOrRemove(element, 'data-series-id', item.seriesId)
    setAttributeOrRemove(element, 'data-program-id', item.id)
    setAttributeOrRemove(element, 'data-channel-id', null) // always remove channel id since we are either series, episode or program

    element.setAttribute('data-ec-name', item.recommendations.name)
    element.setAttribute('data-ec-id', item.recommendations.contentId)

    if (!item.images) {
      return
    }

    const image = item.images[1] || item.images[0]
    const imgElement = slot.querySelector('img.shelf-element-thumbnail')
    if (image && imgElement) {
      imgElement.setAttribute('data-src', image.src)
      imgElement.setAttribute('data-srcset', convertToSrcSet(item.images))
    }
  }
}

function load () {
  showSources()
  updateLastSeenSlots()

  // track tile select for shelf links
  nodelistToArray(
    document.querySelectorAll('.ocean .ocean__element-pages a')
  ).forEach(element => trackTileSelect(element))
}

export default {
  load
}
